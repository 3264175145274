<template>
  <v-dialog
    persistent
    :value="true"
    width="800"
  >
    <v-card>
      <v-card-title>
        {{ $t('edit') | capitalize() }} {{ client.name }}
      </v-card-title>
      <v-card-text>
        <client-form
          v-model="modified"
          :loading="requesting"
          :errors="errors"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          :loading="requesting"
          @click="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="requesting"
          @click="submit"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EntityClientsRepository from '@/api/EntityClientsRepository'
import ClientForm from '@/views/dashboard/components/Clients/ClientForm'
import clone from 'clone'

export default {
  name: 'ClientUpdate',
  components: { ClientForm },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      modified: {
        name: '',
        email: '',
        phone: '',
        vat_number: '',
        address: '',
        city: '',
        province: '',
        post_code: '',
      },
      requesting: false,
      errors: {
        name: null,
        email: null,
        phone: null,
        vat_number: null,
        address: null,
        city: null,
        province: null,
        post_code: null,
      },
    }
  },
  created () {
    this.modified = clone(this.client)
  },
  methods: {
    submit () {
      this.cleanErrors()
      this.requesting = true
      EntityClientsRepository.update(this.client.entity_id, this.client.id, this.modified)
        .then(({ data }) => {
          this.requesting = false
          this.$emit('clientCreated', data)
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const { data } = response
            Object.keys(data.errors).forEach(key => {
              this.errors[key] = data.errors[key]
            })
          }
        })
    },
    cleanErrors () {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = null
      })
    },
  },
}
</script>

<style scoped>

</style>
