import Repository from './Repository'

function buildUrl (entityId, clientId = null) {
  let url = `/api/entities/${entityId}/clients`
  if (clientId) {
    url = `${url}/${clientId}`
  }

  return url
}

export default {
  index (entityId, params) {
    return Repository.get(buildUrl(entityId), { params })
  },
  store (entityId, body) {
    return Repository.post(buildUrl(entityId), body)
  },
  update (entityId, clientId, body) {
    return Repository.put(buildUrl(entityId, clientId), body)
  },
}
