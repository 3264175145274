<template>
  <div>
    <v-row class="mt-2 py-4 px-2 justify-end">
      <seen-by :roles-ids="[$root.$data.rolesIds.entityAdmin]">
        <v-btn
          class="primary"
          @click="showCreateClient = true"
          @cancel="showCreateClient = false"
        >
          {{ $t('c.createClient') }}
        </v-btn>
      </seen-by>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :loading="loading"
      :items="clients"
      item-key="id"
      class="pt-8 "
      loading-text="Loading... Please wait"
      hide-default-footer
      disable-sort
      disable-pagination
    >
      <template v-slot:header.action="{}">
        <seen-by :roles-ids="[$root.$data.rolesIds.entityAdmin]">
          {{ $t('actions') }}
        </seen-by>
      </template>
      <template v-slot:item.action="{ item }">
        <seen-by :roles-ids="[$root.$data.rolesIds.entityAdmin]">
          <div class="my-2">
            <v-btn
              class="primary"
              small
              @click="clientSelected = item"
            >
              {{ $t('edit') }}
            </v-btn>
          </div>
        </seen-by>
      </template>
    </v-data-table>
    <seen-by :roles-ids="[$root.$data.rolesIds.entityAdmin]">
      <client-update
        v-if="clientSelected"
        :client="clientSelected"
        @cancel="clientSelected=null"
      />
      <client-create
        v-if="showCreateClient"
        :entity-id="entityId"
        @cancel="showCreateClient = false"
        @clientCreated="handleClientCreate"
      />
    </seen-by>
  </div>
</template>

<script>
import EntityClientsRepository from '@/api/EntityClientsRepository'
import ClientCreate from '@/views/dashboard/components/Clients/ClientCreate'
import ClientUpdate from '@/views/dashboard/components/Clients/ClientUpdate'
import SeenBy from '@/views/dashboard/components/SeenBy'

export default {
  name: 'EntityClients',
  components: { SeenBy, ClientUpdate, ClientCreate },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      clients: [],
      clientSelected: null,
      search: '',
      headers: [
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('city'), value: 'city' },
        { text: this.$t('phone'), value: 'phone' },
        { text: 'Actions', value: 'action', sortable: false, align: 'end' },
      ],
      showCreateClient: false,
      loading: true,
    }
  },
  created () {
    this.getClients()
  },
  methods: {
    getClients () {
      EntityClientsRepository.index(this.entityId, {})
        .then(({ data }) => {
          this.clients = data.data
          this.loading = false
        })
        .catch(() => {
        })
    },
    handleClientCreate (client) {
      this.clients.push(client)
      this.showCreateClient = false
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          createClient: 'Create Client',

        },
      },
      it: {
        c: {
          createClient: 'Creare Cliente',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
