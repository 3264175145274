<template>
  <v-dialog
    persistent
    :value="true"
    width="800"
  >
    <v-card>
      <v-card-title>
        {{ $t('c.title') }}
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="client.name"
                  :label="$t('name') | capitalize"
                  :error-messages="errors.name"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.email"
                  :label="$t('email') | capitalize"
                  :error-messages="errors.email"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.phone"
                  :error-messages="errors.phone"
                  :label="$t('phone') | capitalize"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.vat_number"
                  :error-messages="errors.vat_number"
                  :label="$t('vatNumber') | capitalize"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="client.address"
                  :error-messages="errors.address"
                  :label="$t('address') | capitalize"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.city"
                  :error-messages="errors.city"
                  label="City"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.province"
                  :error-messages="errors.province"
                  :label="$t('city') | capitalize"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.post_code"
                  :error-messages="errors.post_code"
                  :label="$t('postCode') | capitalize"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          :loading="requesting"
          @click="$emit('cancel')"
        >
          {{ $t('back') }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="requesting"
          @click="submit"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EntityClientsRepository from '@/api/EntityClientsRepository'

export default {
  name: 'ClientCreate',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      client: {
        name: '',
        email: '',
        phone: '',
        vat_number: '',
        address: '',
        city: '',
        province: '',
        post_code: '',
      },
      requesting: false,
      errors: {
        name: null,
        email: null,
        phone: null,
        vat_number: null,
        address: null,
        city: null,
        province: null,
        post_code: null,
      },
    }
  },
  methods: {
    submit () {
      this.requesting = true
      this.cleanErrors()
      EntityClientsRepository.store(this.entityId, this.client)
        .then(({ data }) => {
          this.$emit('clientCreated', data)
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            const { data } = response
            Object.keys(data.errors).forEach(key => {
              this.errors[key] = data.errors[key]
            })
          }
          this.requesting = false
        })
    },
    cleanErrors () {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = null
      })
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Create Client',

      },
      it: {
        title: 'Creare Cliente',
      },
    },
  },
}
</script>

<style scoped>

</style>
