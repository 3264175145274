<template>
  <v-form>
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
        >
          <slot
            v-if="$slots['name']"
            name="name"
          />

          <v-text-field
            v-else
            v-model="value.name"
            :label="$t('Name') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.name : null"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.email"
            :label="$t('email') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.email : null"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.phone"
            :label="$t('phone') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.phone : null"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.vat_number"
            :label="$t('vatNumber') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.vat_number : null"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="value.address"
            :label="$t('address') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.address : null"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.city"
            :label="$t('city') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.city : null"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.province"
            :label="$t('province') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.province : null"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.post_code"
            :label="$t('postCode') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.post_code : null"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'ClientForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
