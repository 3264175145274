<template>
  <div v-if="itSees">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SeenBy',
  props: {
    rolesIds: {
      type: Array,
      required: true,
    },
  },
  computed: {
    role () {
      return this.$store.getters.getUserRole
    },
    itSees () {
      return this.rolesIds.includes(this.role.id)
    },
  },
}
</script>

<style scoped>

</style>
